<template>
    <div class="wrapper passport conts register">
      
        <div class="header">
            <div class="row no-gutters">
                <div class="col-2"></div>
                <div class="col-8 text-center">
                    <span class="btn small" style="width: 100%; display: none"
                        >注册帐户</span
                    >
                </div>
                <div class="col-2 text-right">
                    <a href="javascript:;" class="btn btn-link text-dark" @click="lang">
                      <i class="material-icons" style="display: inline-block; margin-right: 10px">language</i>
                      </a>
                </div>
            </div>
        </div>
        

        <!-- header ends -->
        <div class="container" style="background: #fff; opacity: 0.95">
            <div class="bg-default">
                <div class="row no-gutters login-row">
                    <div class="col align-self-center px-3 text-center mt-3">
                        <p class="mt-2"></p>
                        <form class="form-signin">
                            <div class="form-group">
                                <div class="names"><span>*</span>{{user_name}}</div>
                                <input
                                    type="text"
                                    name="user_name"
                                    class="form-control form-control-lg inputs text-center"
                                    :placeholder="enter_name"
                                    value=""
                                    required=""
                                    autofocus=""
                                    v-model="user.user_name"
                                />
                            </div>
                            <div class="names"><span>*</span>{{platform_account}}</div>
                            <div
                                class="form-group input-group"
                                style="display: flex"
                            >
                                <input
                                    type="text"
                                    id="phone"
                                    name="tel"
                                    :placeholder="please_set"
                                    class="form-control"
                                    style="flex: 3"
                                    v-model="user.tel"
                                    aria-label="Recipient's username"
                                />
                            </div>
                            <div class="form-group">
                                <div class="names"><span>*</span>{{r_password}}</div>
                                <input
                                    type="password"
                                    name="pwd"
                                    class="form-control form-control-lg text-center"
                                    :placeholder="your_password"
                                    value=""
                                    required=""
                                    autofocus=""
                                    autocomplate="off"
                                    v-model="user.pwd"
                                /><input type="text" style="display: none" />
                            </div>
                            <div class="form-group">
                                <div class="names"><span>*</span>{{qu_newpass}}</div>
                                <input
                                    type="password"
                                    name="deposit_pwd"
                                    class="form-control form-control-lg text-center"
                                    :placeholder="login_pass2"
                                    value=""
                                    required=""
                                    autofocus=""
                                    autocomplate="off"
                                    v-model="user.deposit_pwd"
                                /><input type="text" style="display: none" />
                            </div>
                            <div class="form-group">
                                <div class="names"><span>*</span>{{payment_password}}</div>
                                <input
                                    type="password"
                                    name="self_pwd"
                                    class="form-control form-control-lg text-center"
                                    :placeholder="enter_password"
                                    value=""
                                    required=""
                                    autofocus=""
                                    autocomplate="off"
                                    v-model="user.self_pwd"
                                /><input type="text" style="display: none" />
                            </div>
                            <div class="names"><span>*</span>{{extension_code}}</div>
                            <!-- <input type="hidden" name="telcode" id="telcode" value="0"> -->
                            <input
                                type="text"
                                name="invite_code"
                                class="form-control form-control-lg text-center"
                                :placeholder="login_qr"
                                value=""
                                required=""
                                autofocus=""
                                v-model="user.invite_code"
                            /><input type="hidden" name="_csrf_" value="" />
                        </form>
                    </div>
                </div>
                <!-- login buttons -->
                <div class="row mx-0 mt-4">
                    <div class="col">
                        <a class="btn btn-default btn-lg btn-rounded shadow btn-block form-buttom"  @click="regit">{{registered_account}}</a>
                    </div>
                </div>
                <!-- login buttons -->
                <div class="row mx-0 text-center justify-content-center">
                    <a href="javascript:;" @click="goLogin" class="mt-4 d-block">{{login_1}}</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Register",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                user_name: "",
                tel: "",
                pwd: "",
                deposit_pwd: "",
                self_pwd: "",
                invite_code: "",
            },
            user_name: "",
            platform_account: "",
            r_password: "",
            qu_newpass: "",
            payment_password: "",
            extension_code: "",
            registered_account: "",
            login_1: "",
            enter_name: "",
            please_set: "",
            your_password: "",
            login_pass2: "",
            enter_password: "",
            login_qr: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // result = JSON.parse(result);
            var info = result.info;
            this.user_name = info.user_name;
            this.platform_account = info.platform_account;
            this.r_password = info.r_password;
            this.qu_newpass = info.qu_newpass;
            this.payment_password = info.payment_password;
            this.extension_code = info.extension_code;
            this.registered_account = info.registered_account;
            this.login_1 = info.login_1;

            this.enter_name = info.enter_name;
            this.please_set = info.please_set;
            this.your_password = info.your_password;
            this.login_pass2 = info.login_pass2;
            this.enter_password = info.enter_password;
            this.login_qr = info.login_qr;

            if(typeof this.user_name == "undefined"){
                this.user_name = 'Название счета';
                this.platform_account = 'Счет Платформы';
                this.r_password = 'Пароль';
                this.qu_newpass = 'Подтвердить пароль';
                this.payment_password = 'Пароль оплаты';
                this.extension_code = 'Расширенный код';
                this.registered_account = 'Регистрационный счет';
                this.login_1 = 'Регистрация';

                this.enter_name = 'Пожалуйста, введите имя пользователя';
                this.please_set = 'Пожалуйста, установите свой аккаунт на платформе.';
                this.your_password = 'Ваш пароль.';
                this.login_pass2 = 'Подтвердите пароль.';
                this.enter_password = 'Введите свой платежный пароль.';
                this.login_qr = 'Введите код приглашения.';
            }
        },
      // 跳转到语言
        lang() {
            window.open('/index/user/lang.html','_self');
        },
        // 注册用户
        async regit() {
            if (this.user.tel == "" || this.user.pwd == "" || this.user.pwd !== this.user.deposit_pwd) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: "The two passwords are inconsistent",
                });
                return false;
            }

            if(this.user.invite_code.trim().length <= 0){
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: this.login_qr,
                });
                return false;
            }

            // var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,13}$/ // 密码6~13位 必须包含大小写字母和数字的组合
            // var pass = re.test(this.user.passwd);
            // if(!pass){
            //   this.$vs.notification({
            //     position:'top-center',
            //     icon:'<span class="material-icons">error</span>',
            //     color:'warn',
            //     title: '错误',
            //     text: `密码6~13位 必须包含大小写字母和数字的组合哦`
            //   })
            //   return false
            // }

            // 注册接口
            var result = await this.$proxy.regdit(this.user);
            // console.log(result);
            if (result.code == 1) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    title: "error",
                    text: result.info,
                });
                return false;
            } else if (result.code == 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">check</span>',
                    color: "success",
                    title: "success",
                    text: "Register success",
                });
                window.open('/index/user/agreement','_self');
            }
        },

        // 去登录
        goLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
.register {
    display: flex;
    justify-content: space-around;
    background: url("../assets/background.png") no-repeat;
    filter: opacity(80%);
    background-position: center center;
    background-size: cover;
    display: inline-block;
    width: 100%;
    min-height: 70vh;
    font-size: 1rem;
    color: #333;
}
.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
}
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.text-center {
    text-align: center!important;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.text-right {
    text-align: right!important;
}
.btn-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.container {
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.form-group {
    margin-bottom: 15px;
}
.names {
    text-align: left;
    margin-bottom: 8px;
    font-size: 12px;
}
.names span {
    color: red;
    font-size: 16px;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-signin input {
    text-align: left !important;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    height: 36px;
    font-size: 14px;
}
.ml-0, .mx-0 {
    margin-left: 0!important;
}
.mt-4, .my-4 {
    margin-top: 1.5rem!important;
}
.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.btn-lg, .btn-group-lg>.btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
}
.btn-block {
    display: block;
    /* width: 100%; */
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.justify-content-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
    margin-top: 15px;
}
a {
    color: #337ab7;
    text-decoration: none;
    font-size: 14px;
}
.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}


</style>
